import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { ApolloClient } from 'apollo-client';
import { HttpLink } from 'apollo-link-http';
import { WebSocketLink } from 'apollo-link-ws';
import { SubscriptionClient } from 'subscriptions-transport-ws';
import { getMainDefinition } from 'apollo-utilities';
import { split, from } from 'apollo-link';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { getInstance } from './auth';

Vue.use(VueApollo);

const errCSS =
  'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;';

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Http endpoint configuration comes from build parameter env var, 
// hardcoded default to localhost or with high prio JWT claim stored in a localStorage.
const httpEndpoint = process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:8080/v1/graphql';
const wsEndpoint = process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:8080/v1/graphql';
const storedUrl = localStorage.getItem('hasura-url');

// Config
export const defaultOptions = {
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  wsEndpoint: storedUrl && process.env.NODE_ENV === 'production' ? `wss://${storedUrl}` : wsEndpoint,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  websocketsOnly: false,
  ssr: false,
  cache: new InMemoryCache(),
  connectToDevTools: process.env.NODE_ENV !== 'production',
};

function createProviderFromClient(apolloClient) {
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
      },
    },
    errorHandler(error) {
      console.log('%cError', errCSS, error.message);
    },
  });
  return apolloProvider;
}

function getStoredAuth(tokenName) {
  if (typeof window !== 'undefined') {
    // get the authentication token from local storage if it exists
    const token = window.localStorage.getItem(tokenName);
    // return the headers to the context so httpLink can read them
    return token ? `Bearer ${token}` : '';
  }
}

const getToken = async () => {
  return await getInstance().getTokenSilently();
};

const getClaims = async () => {
  return await getInstance().getIdTokenClaims();
};

const httpLink = new HttpLink({
  uri: () => (defaultOptions.httpEndpoint)
});

const authLink = setContext(async () => ({
  headers: {
    // "X-Hasura-Role": "admin",
    // Authorization: await getAndStoreToken(),
    Authorization: getStoredAuth(defaultOptions.tokenName),
  },
}));

const wsClient = new SubscriptionClient(defaultOptions.wsEndpoint, {
  reconnect: true,
  connectionParams: () => {
    const Authorization = getStoredAuth(defaultOptions.tokenName);
    return Authorization ? { Authorization, headers: { Authorization } } : {};
  },
});

// Create the subscription websocket link
const wsLink = new WebSocketLink(wsClient);

const link = split(
  // split based on operation type
  ({ query }) => {
    const definition = getMainDefinition(query);
    return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
  },
  wsLink,
  httpLink
);

// Call this in the Vue app file
export function createProvider(options = {}) {
  // Create apollo client
  const apolloClient = new ApolloClient({
    ...{
      link: from([authLink, link]),
    },
    ...defaultOptions,
    ...options,
  });

  apolloClient.restartWebsocketConnection = () => {
    if (wsLink) {
      wsLink.subscriptionClient.close(true);
    }
  };

  return createProviderFromClient(apolloClient);
}

// Manually call this when user log in
export async function onLogin(apolloClient) {
  const token = await getToken();
  const claims = await getClaims();

  if (typeof localStorage !== 'undefined' && token) {
    localStorage.setItem(AUTH_TOKEN, token);
    localStorage.setItem('hasura-url', claims['https://cloud.mitakus.eu/jwt/claims/hurl']);
    if (process.env.NODE_ENV === 'production') {
      defaultOptions.httpEndpoint = `https://${claims['https://cloud.mitakus.eu/jwt/claims/hurl']}`;
    }
  }
  try {
    await apolloClient.resetStore();
    await apolloClient.restartWebsocketConnection();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message);
  }
}

// Manually call this when user log out
export async function onLogout(apolloClient) {
  if (typeof localStorage !== 'undefined') {
    localStorage.removeItem(AUTH_TOKEN);
    localStorage.removeItem('hasura-url');
  }
  try {
    await apolloClient.resetStore();
    await apolloClient.restartWebsocketConnection();
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message);
  }
}