<template>
  <div class="bgr home">
    <img class="pt-5 mt-5 mtk-logo" alt="MLogo" src="../assets/images/logo.png" />

    <b-jumbotron class="bgr" bg-variant="transparent" text-variant="white" header-level="5">
      <template #header> {{ $t('homeSection.title') }} </template>
      <h4 class="pt-2">
        {{ $t('homeSection.subtitle') }}
      </h4>
      <hr class="my-4" />
      <p class="p-3">
        {{ $t('homeSection.desc') }}
      </p>
    </b-jumbotron>
    <!-- <b-container class="embed-container">
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://biteable.com/watch/embed/awesome-mitakus-product-launch-2760806"
        allowfullscreen
      ></b-embed>
      <br>
      <b-embed
        type="iframe"
        aspect="16by9"
        src="https://www.youtube.com/embed/WpUlf1ZAGxw?rel=0"
        allowfullscreen
      ></b-embed>
      <br>
    </b-container> -->
  </div>
</template>

<script>
export default {
  name: 'Home',
  components: {},
};
</script>

<style scoped>
.home {
  height: 100vh;
}
.embed-container {
  max-width: 600px;
}
</style>
