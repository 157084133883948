<template>
  <div id="nav">
    <b-navbar toggleable="md" type="dark" variant="info">
      <b-navbar-brand href="#">
        <img class="mtk-logo" alt="MLogo" src="../assets/images/logo.png" />
      </b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav class="pt-1">
          <router-link class="nav-link text-white" :to="`/${$i18n.locale}/`">
            {{ $t('nav.home') }}</router-link
          >
          <router-link class="nav-link text-white" :to="`/${$i18n.locale}/about`">
            {{ $t('nav.about') }}
          </router-link>
          <b-nav-item-dropdown v-if="isChef" :text="$t('nav.shopHome')" left>
            <b-dropdown-item :to="{ name: 'Shop' }"> {{ $t('nav.shopHome') }} </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'NameCat' }">
              {{ $t('nav.nameCategories') }}
            </b-dropdown-item>
            <!-- <b-dropdown-item :to="{ name: 'DayCat' }">Day Categories</b-dropdown-item> -->
            <b-dropdown-item :to="{ name: 'ShopProducts' }">
              {{ $t('nav.products') }}
            </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'ShopOrders' }"> {{ $t('nav.orders') }} </b-dropdown-item>
            <b-dropdown-item :to="{ name: 'Messages' }"> {{ $t('nav.messages') }} </b-dropdown-item>
          </b-nav-item-dropdown>
          <router-link v-if="isChef" class="nav-link" :to="`/${$i18n.locale}/forecast`">
            {{ $t('nav.forecast') }}
          </router-link>
          <router-link v-if="isChef" class="nav-link" :to="`/${$i18n.locale}/analytics`">
            {{ $t('nav.analytics') }}
          </router-link>
          <router-link v-if="isChef" class="nav-link" :to="`/${$i18n.locale}/dashboards`">
            {{ $t('nav.dashboards') }}
          </router-link>
        </b-navbar-nav>
        <!-- Right aligned nav items -->
        <b-navbar-nav class="ml-auto">
          <!-- <b-nav-form class="mr-5 ml-2">
            <b-form-input size="sm" class="mr-sm-2" placeholder="Search"></b-form-input>
            <b-button size="sm" class="my-2 my-sm-0" type="submit">Search</b-button>
          </b-nav-form> -->
          <language-switcher />
          <div class="m-1">
            <b-avatar v-if="!$auth.isAuthenticated" />
            <b-avatar v-if="$auth.isAuthenticated" :src="$auth.user.picture"></b-avatar>
          </div>
          <b-nav-item-dropdown class="pt-1" v-if="$auth.isAuthenticated" right>
            <!-- Using 'button-content' slot -->
            <template>
              <em class="email-content">{{ $auth.user.email }}</em>
            </template>
            <div class="dropdown-divider"></div>
            <b-dropdown-item :to="`/${$i18n.locale}/profile`">{{
              $t('nav.profile')
            }}</b-dropdown-item>
            <b-dropdown-item @click="logout">{{ $t('nav.signOut') }}</b-dropdown-item>
          </b-nav-item-dropdown>
          <div class="pt-1 m-1">
            <b-button size="sm" class="mx-auto" v-if="!$auth.isAuthenticated" @click="login">{{
              $t('btnOptions.login')
            }}</b-button>
          </div>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import { onLogout } from '../vue-apollo';
import LanguageSwitcher from './LanguageSwitcher.vue';

export default {
  components: { LanguageSwitcher },
  name: 'TopNavBar',
  data() {
    return {
      isChef: false,
      langs: ['de', 'en'],
    };
  },
  methods: {
    // Log the user in
    login() {
      this.$auth.loginWithRedirect();
    },
    // Log the user out
    logout() {
      onLogout(this.$apolloProvider.defaultClient);
      this.$auth.logout({
        returnTo: window.location.origin,
      });
    },
    onUserRolesChange(roles) {
      if (roles.indexOf('chef') !== -1) {
        this.isChef = true;
      }
    },
    watchRoles() {
      this.$watch(
        () => this.$auth.user['https://cloud.mitakus.eu/jwt/claims/roles'],
        this.onUserRolesChange
      );
    },
  },
  watch: {
    '$auth.isAuthenticated': function(newVal) {
      if (newVal) {
        this.watchRoles();
      }
    },
  },
};
</script>

<style>
.mtk-logo {
  height: 40px;
}

/* Nav Bar line animation */
.router-link-exact-active {
  position: relative;
}
.router-link-exact-active::after {
  content: '';
  position: absolute;
  width: 50%;
  height: 1px;
  background-color: whitesmoke;
  bottom: 15%;
  left: 50%;
  transform: translateX(-50%);
  animation-duration: 3s;
  animation-name: slidein;
  animation-direction: alternate;
}
@keyframes slidein {
  from {
    width: 50%;
  }

  75% {
    width: 100%;
  }

  to {
    width: 50%;
  }
}
.email-content {
  padding: 24px;
  color: gray;
  font-style: normal;
}
/* Responsive design */
@media only screen and (max-width: 766px) {
  .router-link-exact-active::after {
    animation: none;
    width: 30px;
  }
}
</style>
