import '@babel/polyfill';
import 'mutationobserver-shim';
import Vue from 'vue';
import './plugins/bootstrap-vue';
import App from './App.vue';
import router from './router';
import VueMeta from 'vue-meta';
Vue.use(VueMeta);
import dayjs from 'dayjs';
import 'dayjs/locale/de';

Vue.filter('deDateTime', (value) => {
  return dayjs(value)
    .locale('de')
    .format('DD/MMMM/YYYY - HH:mm');
});

Vue.config.productionTip = false;

// Import the plugin here
import { Auth0Plugin } from './auth';

import { createProvider } from './vue-apollo';
import i18n from './i18n';

// Import the Auth0 configuration
import { domain, clientId, audience } from '../auth_config.json';
// Check for configuration coming from server via window
const conf = window.mtkConfig || {};
// Install the authentication plugin here
Vue.use(Auth0Plugin, {
  domain: conf.domain || domain,
  clientId: conf.clientId || clientId,
  audience: conf.audience || audience,
  onRedirectCallback: (appState) => {
    router.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
  },
});

router.beforeEach((to, from, next) => {
  let language = to.params.lang;
  if (!language) {
    language = 'de';
  }

  i18n.locale = language;
  next();
});

new Vue({
  router,
  apolloProvider: createProvider(),
  i18n,
  render: (h) => h(App),
}).$mount('#app');
