<template>
  <div id="app">
    <loading v-if="this.$auth.loading" />
    <top-nav-bar />
    <router-view />
  </div>
</template>

<script>
import Loading from './components/Loading.vue';
import TopNavBar from './components/TopNavBar.vue';

export default {
  name: 'App',
  components: {
    TopNavBar,
    Loading,
  },
  metaInfo() {
    return {
      title: 'Mitakus - Analytics backend',
      meta: [
        {
          name: 'description',
          content:
            'Mitakus analytics provides a web-based software that generates precise forecasts and menu recommendations with the help of AI algorithms based on internal and external factors, such as the weather, holidays, vacations, events and dietary restrictions and preferences. With the help of these predictions and recommendations Mitakus helps food service operators and restaurants to reduce overproduction and underproduction and to find the perfect menu to meet guest demand and needs, such as diet restrictions etc.',
        },
        {
          property: 'og:title',
          content:
            'Mitakus - Analytics backend - configure and manage precise forecasts and menu recommendations',
        },
        { property: 'og:site_name', content: 'Forecasts' },
        { property: 'og:type', content: 'website' },
        { name: 'robots', content: 'index,follow' },
      ],
    };
  },
};
</script>

<style>
body,
html {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  min-height: 100%;
  height: 100%;
  width: 100%;
  margin: 0;
  background: no-repeat center center fixed;
  background-image: linear-gradient(rgba(0, 71, 102, 0.8), rgba(0, 71, 102, 0.5)),
    url('./assets/images/povarweb.jpg');
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  margin-top: 0;
}

h1,
h2,
h3,
h4,
p {
  color: rgb(205, 205, 205);
}
.bgr {
  background: no-repeat center center fixed;
  background-image: linear-gradient(rgba(0, 71, 102, 0.8), rgba(0, 71, 102, 0.5)),
    url('./assets/images/povarweb.jpg');
}
.center {
  margin-left: 50%;
}
.mtk-shadow {
  box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  -webkit-box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 2px 3px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
.maxw {
  max-width: 800px;
}
.usp-bgr {
  background-color: rgba(255, 255, 255, 0.72);
}
</style>