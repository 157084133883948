<template>
  <div class="relative">
    <button
      href="#"
      class="flex items-center language-box"
      @click="toggleVisibility"
      @keydown.space.exact.prevent="toggleVisibility"
      @keydown.esc.exact="hideDropdown"
      @keydown.shift.tab="hideDropdown"
      @keydown.up.exact.prevent="startArrowKeys"
      @keydown.down.exact.prevent="startArrowKeys"
    >
      <img :src="`/static/flag_${$i18n.locale}.svg`" alt="flag" class="w-8 h-8" />
      <span class="ml-2">{{ $i18n.locale.toUpperCase() }}</span>
      <svg
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
      >
        <path
          class="heroicon-ui"
          d="M15.3 9.3a1 1 0 0 1 1.4 1.4l-4 4a1 1 0 0 1-1.4 0l-4-4a1 1 0 0 1 1.4-1.4l3.3 3.29 3.3-3.3z"
        ></path>
      </svg>
    </button>
    <transition name="dropdown-fade">
      <div class="flex-dropdown">
        <ul
          v-if="isVisible"
          ref="dropdown"
          class="absolute normal-case z-30 font-normal xs:left-0 lg:right-0 bg-white shadow overflow-hidden rounded w-48 border mt-2 py-1 lg:z-20"
        >
          <li>
            <a
              href="#"
              @click.prevent="setLocale('en')"
              ref="account"
              class="flex items-center px-3 py-3 hover:bg-gray-200"
              @keydown.up.exact.prevent=""
              @keydown.esc.exact="hideDropdown"
            >
              <img src="/static/flag_en.svg" alt="english flag" class="h-8 w-8" />
              <span class="ml-2">En</span>
            </a>
          </li>
          <li>
            <a
              href="#"
              @click.prevent="setLocale('de')"
              class="flex items-center px-3 py-3 hover:bg-gray-200"
              @keydown.shift.tab="focusPrevious(false)"
              @keydown.up.exact.prevent="focusPrevious(true)"
              @keydown.down.exact.prevent=""
              @keydown.tab.exact="hideDropdown"
              @keydown.esc.exact="hideDropdown"
            >
              <img src="/static/flag_de.svg" alt="Germany flag" class="h-0 w-0" />
              <span class="ml-2">De</span>
            </a>
          </li>
        </ul>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isVisible: false,
      focusedIndex: 0,
    };
  },
  methods: {
    toggleVisibility() {
      this.isVisible = !this.isVisible;
    },
    hideDropdown() {
      this.isVisible = false;
      this.focusedIndex = 0;
    },
    startArrowKeys() {
      if (this.isVisible) {
        // this.$refs.account.focus()
        this.$refs.dropdown.children[0].children[0].focus();
      }
    },
    focusPrevious(isArrowKey) {
      this.focusedIndex = this.focusedIndex - 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusNext(isArrowKey) {
      this.focusedIndex = this.focusedIndex + 1;
      if (isArrowKey) {
        this.focusItem();
      }
    },
    focusItem() {
      this.$refs.dropdown.children[this.focusedIndex].children[0].focus();
    },
    setLocale(locale) {
      this.$i18n.locale = locale;
      this.$router
        .push({
          params: { lang: locale },
        })
        .catch(() => {});
      this.hideDropdown();
    },
  },
};
</script>

<style scoped>
.dropdown-fade-enter-active,
.dropdown-fade-leave-active {
  transition: all 0.1s ease-in-out;
}
.dropdown-fade-enter,
.dropdown-fade-leave-to {
  opacity: 0;
  transform: translateY(-12px);
}
img {
  width: 24px;
  height: 24px;
}

.absolute li {
  background: transparent;
}
.language-box {
  margin-top: 10px;
  background: transparent;
  border: none;
  outline: none;
  color: white;
  -webkit-appearance: button;
  appearance: button;
}
.absolute {
  position: absolute;
  list-style: none;
  padding-left: 0;
}

@media only screen and (max-width: 766px) {
  .absolute {
    position: static;
    width: 120px;
  }
  .flex-dropdown {
    justify-content: center;
    display: flex;
  }
}
</style>
