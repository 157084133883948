import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import { authGuard } from '../auth/authGuard';
import { getInstance } from '../auth/index';
import i18n from '../i18n';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    redirect: `/${i18n.locale}`,
  },
  {
    path: '/:lang',
    component: {
      render(c) {
        return c('router-view');
      },
    },
    children: [
      {
        path: '/',
        name: 'Home',
        component: Home,
      },
      {
        path: 'about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
      },
      {
        path: 'analytics',
        name: 'Analytics',
        component: () => import(/* webpackChunkName: "analytics" */ '../views/Analytics.vue'),
        meta: { authGuard: true, requireRole: 'chef' },
      },
      {
        path: 'dashboards',
        name: 'Dashboards',
        component: () => import(/* webpackChunkName: "dashboards" */ '../views/Dashboards.vue'),
        meta: { authGuard: true, requireRole: 'chef' },
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Profile.vue'),
        beforeEnter: authGuard,
      },
      {
        path: 'imprint',
        name: 'Imprint',
        component: () => import(/* webpackChunkName: "profile" */ '../views/Imprint.vue'),
        beforeEnter: authGuard,
      },
      {
        path: 'privacy-policy',
        name: 'Privacy Policy',
        component: () => import(/* webpackChunkName: "profile" */ '../views/PrivacyPolicy.vue'),
        beforeEnter: authGuard,
      },
      {
        path: '/shop',
        name: 'Shop',
        component: () => import(/* webpackChunkName: "shop" */ '../views/shop/ShopHome.vue'),
        meta: { authGuard: true, requireRole: 'chef' },
        children: [
          {
            path: 'name-categories',
            name: 'NameCat',
            meta: { authGuard: true, requireRole: 'chef' },
            component: () => import(/* webpackChunkName: "shop" */ '../views/shop/NameCat.vue'),
          },
          // {
          //   path: 'day-categories',
          //   name: 'DayCat',
          //   meta: { authGuard: true, requireRole: 'chef' },
          //   component: () => import(/* webpackChunkName: "shop" */ '../views/shop/DayCat.vue'),
          // },
          {
            path: 'products',
            name: 'ShopProducts',
            meta: { authGuard: true, requireRole: 'chef' },
            component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Products.vue'),
          },
          {
            path: 'orders',
            name: 'ShopOrders',
            meta: { authGuard: true, requireRole: 'chef' },
            component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Orders.vue'),
          },
          {
            path: 'messages',
            name: 'Messages',
            meta: { authGuard: true, requireRole: 'chef' },
            component: () => import(/* webpackChunkName: "shop" */ '../views/shop/Messages.vue'),
          },
        ],
      },
      {
        path: 'forecast',
        name: 'Forecast',
        component: () => import(/* webpackChunkName: "forecast" */ '../views/Forecast.vue'),
        meta: { authGuard: true, requireRole: 'chef' },
      },
    ],
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.authGuard) {
    authGuard(to, from, next);
    if (to.meta.requireRole) {
      const authService = getInstance();
      authService.$watch('loading', (loading) => {
        if (loading === false) {
          const auth0Roles = authService.user['https://cloud.mitakus.eu/jwt/claims/roles'];
          if (auth0Roles.indexOf(to.meta.requireRole) === -1) {
            next({ name: 'Home' });
          }
        }
      });
    }
  } else {
    next();
  }
});

export default router;
